<template>
  <section class="page-residence">
    <div class="banner">
      <p class="hd">JWP RESIDENCE</p>
      <p class="title">
        <span>Residential Housing</span>
      </p>
      <p class="desc">
        <span
          >Residential Housing is one of the earliest development sectors under
          the JWP brand, being the most complete and having various property
          designs and types to offer.</span
        >
      </p>
    </div>
    <ul class="wrap">
      <template v-for="country in listData">
        <li
          class="country-projects"
          v-if="country.list.length > 0"
          :key="country.belong"
        >
          <a
            class="hd"
            :href="`/landmark/residence/project-more?belong=${country.belong}`"
          >
            <img :src="country.icon" />
            <span class="en">{{ country.enName }}</span>
            <img
              class="more"
              src="//static.hoolihome.com/global-jwp/assets/m/arrow-more-right.png"
            />
          </a>
          <project-list :projects="country.list"></project-list>
        </li>
      </template>
    </ul>
  </section>
</template>

<script>
import ProjectList from "@/components/project/ProjectList";

export default {
  components: { ProjectList },
  data() {
    return {
      listData: [
        {
          icon:
            "//static.hoolihome.com/global-jwp/assets/m/landmark/residence/cyprus.png",
          belongText: "塞浦路斯",
          enName: "CYPRUS",
          belong: 2,
          list: []
        },
        {
          icon:
            "//static.hoolihome.com/global-jwp/assets/m/landmark/residence/portugal.png",
          belongText: "葡萄牙",
          enName: "PORTUGAL",
          belong: 4,
          list: []
        },
        {
          icon:
            "//static.hoolihome.com/global-jwp/assets/m/landmark/residence/greece.png",
          belongText: "希腊",
          enName: "GREECE",
          belong: 3,
          list: []
        },
        {
          icon:
            "//static.hoolihome.com/global-jwp/assets/m/landmark/residence/turkey.png",
          belongText: "土耳其",
          enName: "TURKEY",
          belong: 5,
          list: []
        },
        {
          icon:
            "//static.hoolihome.com/global-jwp/assets/m/landmark/residence/japan.png",
          belongText: "日本",
          enName: "JAPAN",
          belong: 6,
          list: []
        }
      ]
    };
  },
  beforeCreate() {
    document.title = "Residential Housing";
  },
  created() {
    const params = {
      type: 2,
      page: 1,
      limit: 3
    };
    this.listData.forEach((item, index) => {
      this.loadData(
        {
          url: this.$apis.jwpList,
          data: {
            ...params,
            belong: item.belong
          }
        },
        index
      );
    });
  },
  methods: {
    loadData(params, index) {
      this.$net.req(params).then(res => {
        if (res) {
          const { data } = res;
          if (data && Array.isArray(data.list)) {
            this.listData[index].list = data.list;
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.page-residence {
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 360px;
    background: url("//static.hoolihome.com/global-jwp/assets/m/landmark/residence/banner.jpg")
      no-repeat center center / cover;
    .hd {
      padding-bottom: 83px;
      font-size: 22px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 30px;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    }
    .title {
      padding-bottom: 16px;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        border-bottom: 2px rgba(255, 255, 255, 0.5) solid;
      }
    }
    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 22px 39px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      span {
        display: inline-block;
        line-height: 17px;
      }
    }
  }
  .wrap {
    padding: 10px 0 40px;
  }
  .country-projects {
    padding: 20px 0;
    .hd {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 22px;
      &:active {
        opacity: 0.8;
      }
      img {
        width: 20px;
      }
      .en {
        margin: 0 8px 0 12px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(38, 38, 38, 1);
        line-height: 25px;
      }
      .more {
        width: 15px;
        margin-left: 4px;
      }
    }
  }
}
</style>
