<template>
  <a
    :class="{ 'project-box': true, 'h-206': !!project.type }"
    :href="project.id ? `/project/${project.id}` : 'javascript:void(0)'"
  >
    <van-image
      class="project-img"
      lazy-load
      fit="cover"
      :src="project.projectImg"
    />
    <div class="info one" v-if="project.type">
      <p class="name van-ellipsis">{{ project.nameEn }}</p>
      <p class="lr location">
        <img
          src="//static.hoolihome.com/global-jwp/assets/m/project/icon-location.png"
        />
        <span class="van-ellipsis">{{ project.propertyLocationEn }}</span>
      </p>
      <p class="lr form">
        <img
          src="//static.hoolihome.com/global-jwp/assets/m/project/icon-form.png"
        />
        <span class="van-ellipsis">{{ project.architecturalFormEn }}</span>
      </p>
      <p class="lr layout">
        <img
          src="//static.hoolihome.com/global-jwp/assets/m/project/icon-layout.png"
        />
        <span class="van-ellipsis">{{ project.apartmentSpaceEn }}</span>
      </p>
    </div>
    <div v-else class="info two">
      <p class="country-text van-ellipsis">{{ project.countryText }}</p>
      <p class="name van-ellipsis">{{ project.name }}</p>
      <p class="lr location">
        <img
          src="//static.hoolihome.com/global-jwp/assets/m/project/icon-location.png"
        />
        <span class="van-ellipsis">{{ project.propertyLocation }}</span>
      </p>
      <p class="lr form">
        <img
          src="//static.hoolihome.com/global-jwp/assets/m/project/icon-form.png"
        />
        <span class="van-ellipsis">{{ project.architecturalForm }}</span>
      </p>
      <p class="lr time">
        <img
          src="//static.hoolihome.com/global-jwp/assets/m/project/icon-time.png"
        />
        <span class="van-ellipsis">{{ project.marketEntryTime }}</span>
      </p>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      required: true,
      default: () => {}
    }
  }
};
</script>

<style lang="less" scoped>
.project-box {
  display: inline-block;
  width: 156px;
  height: 212px;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 4px 2px 10px 0px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  overflow: hidden;
  &.h-206 {
    height: 206px;
  }
  &:active {
    opacity: 0.9;
  }
  .project-img {
    width: 100%;
    height: 94px;
  }
  .info {
    padding: 8px 12px 0;
    &.one {
      .name {
        font-size: 14px;
        font-weight: bold;
        color: rgba(1, 61, 125, 1);
        line-height: 20px;
      }
      .lr {
        display: flex;
        align-items: center;
        margin-top: 2px;
        img {
          width: 10px;
        }
        span {
          margin-left: 5px;
          font-size: 10px;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 14px;
        }
        &.location {
          margin-top: 8px;
          img {
            width: 15px;
            margin-left: -2px;
          }
        }
        &.form {
          margin-top: 3px;
          img {
            width: 13px;
            margin-left: -1px;
          }
          span {
            margin-top: 2px;
          }
        }
        &.layout {
          margin-top: 4px;
          img {
            width: 12px;
          }
          span {
            margin-left: 5px;
          }
        }
      }
    }
    &.two {
      .country-text {
        font-size: 14px;
        font-weight: bold;
        color: rgba(11, 54, 154, 1);
        line-height: 20px;
      }
      .name {
        height: 17px;
        line-height: 17px;
        margin-top: 2px;
        font-size: 12px;
        font-weight: 400;
        color: rgba(11, 54, 154, 1);
      }
      .lr {
        display: flex;
        align-items: center;
        margin-top: 2px;
        img {
          width: 10px;
        }
        span {
          margin-left: 5px;
          font-size: 10px;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 14px;
        }
        &.location {
          margin-top: 7px;
          img {
            width: 15px;
            margin-left: -2px;
          }
        }
        &.form {
          img {
            width: 13px;
            margin-left: -1px;
          }
          span {
            margin-top: 2px;
          }
        }
        &.time {
          span {
            margin-left: 7px;
          }
        }
      }
    }
  }
}
</style>
