<template>
  <section class="project-list">
    <ul>
      <li v-for="(project, index) in projects" :key="index">
        <project-box :project="project"></project-box>
      </li>
    </ul>
  </section>
</template>

<script>
import ProjectBox from "@/components/project/ProjectBox";

export default {
  components: { ProjectBox },
  props: {
    projects: {
      type: Array,
      required: true,
      default: () => []
    }
  }
};
</script>

<style lang="less" scoped>
.project-list {
  width: 100vw;
  overflow-x: scroll;
  ul {
    display: flex;
    width: auto;
    padding-left: 22px;
    li {
      flex-shrink: 0;
      padding-right: 14px;
      overflow-y: hidden;
      &:last-child {
        padding-right: 22px;
      }
    }
  }
}
</style>
